/**
 * MUI Colors module
 */
.mui--color-red-50 {
  color: #FFEBEE !important; }

.mui--bg-color-red-50 {
  background-color: #FFEBEE !important; }

.mui--color-red-100 {
  color: #FFCDD2 !important; }

.mui--bg-color-red-100 {
  background-color: #FFCDD2 !important; }

.mui--color-red-200 {
  color: #EF9A9A !important; }

.mui--bg-color-red-200 {
  background-color: #EF9A9A !important; }

.mui--color-red-300 {
  color: #E57373 !important; }

.mui--bg-color-red-300 {
  background-color: #E57373 !important; }

.mui--color-red-400 {
  color: #EF5350 !important; }

.mui--bg-color-red-400 {
  background-color: #EF5350 !important; }

.mui--color-red-500 {
  color: #F44336 !important; }

.mui--bg-color-red-500 {
  background-color: #F44336 !important; }

.mui--color-red {
  color: #F44336 !important; }

.mui--bg-color-red {
  background-color: #F44336 !important; }

.mui--color-red-600 {
  color: #E53935 !important; }

.mui--bg-color-red-600 {
  background-color: #E53935 !important; }

.mui--color-red-700 {
  color: #D32F2F !important; }

.mui--bg-color-red-700 {
  background-color: #D32F2F !important; }

.mui--color-red-800 {
  color: #C62828 !important; }

.mui--bg-color-red-800 {
  background-color: #C62828 !important; }

.mui--color-red-900 {
  color: #B71C1C !important; }

.mui--bg-color-red-900 {
  background-color: #B71C1C !important; }

.mui--color-red-A100 {
  color: #FF8A80 !important; }

.mui--bg-color-red-A100 {
  background-color: #FF8A80 !important; }

.mui--color-red-A200 {
  color: #FF5252 !important; }

.mui--bg-color-red-A200 {
  background-color: #FF5252 !important; }

.mui--color-red-A400 {
  color: #FF1744 !important; }

.mui--bg-color-red-A400 {
  background-color: #FF1744 !important; }

.mui--color-red-A700 {
  color: #D50000 !important; }

.mui--bg-color-red-A700 {
  background-color: #D50000 !important; }

.mui--color-pink-50 {
  color: #FCE4EC !important; }

.mui--bg-color-pink-50 {
  background-color: #FCE4EC !important; }

.mui--color-pink-100 {
  color: #F8BBD0 !important; }

.mui--bg-color-pink-100 {
  background-color: #F8BBD0 !important; }

.mui--color-pink-200 {
  color: #F48FB1 !important; }

.mui--bg-color-pink-200 {
  background-color: #F48FB1 !important; }

.mui--color-pink-300 {
  color: #F06292 !important; }

.mui--bg-color-pink-300 {
  background-color: #F06292 !important; }

.mui--color-pink-400 {
  color: #EC407A !important; }

.mui--bg-color-pink-400 {
  background-color: #EC407A !important; }

.mui--color-pink-500 {
  color: #E91E63 !important; }

.mui--bg-color-pink-500 {
  background-color: #E91E63 !important; }

.mui--color-pink {
  color: #E91E63 !important; }

.mui--bg-color-pink {
  background-color: #E91E63 !important; }

.mui--color-pink-600 {
  color: #D81B60 !important; }

.mui--bg-color-pink-600 {
  background-color: #D81B60 !important; }

.mui--color-pink-700 {
  color: #C2185B !important; }

.mui--bg-color-pink-700 {
  background-color: #C2185B !important; }

.mui--color-pink-800 {
  color: #AD1457 !important; }

.mui--bg-color-pink-800 {
  background-color: #AD1457 !important; }

.mui--color-pink-900 {
  color: #880E4F !important; }

.mui--bg-color-pink-900 {
  background-color: #880E4F !important; }

.mui--color-pink-A100 {
  color: #FF80AB !important; }

.mui--bg-color-pink-A100 {
  background-color: #FF80AB !important; }

.mui--color-pink-A200 {
  color: #FF4081 !important; }

.mui--bg-color-pink-A200 {
  background-color: #FF4081 !important; }

.mui--color-pink-A400 {
  color: #F50057 !important; }

.mui--bg-color-pink-A400 {
  background-color: #F50057 !important; }

.mui--color-pink-A700 {
  color: #C51162 !important; }

.mui--bg-color-pink-A700 {
  background-color: #C51162 !important; }

.mui--color-purple-50 {
  color: #F3E5F5 !important; }

.mui--bg-color-purple-50 {
  background-color: #F3E5F5 !important; }

.mui--color-purple-100 {
  color: #E1BEE7 !important; }

.mui--bg-color-purple-100 {
  background-color: #E1BEE7 !important; }

.mui--color-purple-200 {
  color: #CE93D8 !important; }

.mui--bg-color-purple-200 {
  background-color: #CE93D8 !important; }

.mui--color-purple-300 {
  color: #BA68C8 !important; }

.mui--bg-color-purple-300 {
  background-color: #BA68C8 !important; }

.mui--color-purple-400 {
  color: #AB47BC !important; }

.mui--bg-color-purple-400 {
  background-color: #AB47BC !important; }

.mui--color-purple-500 {
  color: #9C27B0 !important; }

.mui--bg-color-purple-500 {
  background-color: #9C27B0 !important; }

.mui--color-purple {
  color: #9C27B0 !important; }

.mui--bg-color-purple {
  background-color: #9C27B0 !important; }

.mui--color-purple-600 {
  color: #8E24AA !important; }

.mui--bg-color-purple-600 {
  background-color: #8E24AA !important; }

.mui--color-purple-700 {
  color: #7B1FA2 !important; }

.mui--bg-color-purple-700 {
  background-color: #7B1FA2 !important; }

.mui--color-purple-800 {
  color: #6A1B9A !important; }

.mui--bg-color-purple-800 {
  background-color: #6A1B9A !important; }

.mui--color-purple-900 {
  color: #4A148C !important; }

.mui--bg-color-purple-900 {
  background-color: #4A148C !important; }

.mui--color-purple-A100 {
  color: #EA80FC !important; }

.mui--bg-color-purple-A100 {
  background-color: #EA80FC !important; }

.mui--color-purple-A200 {
  color: #E040FB !important; }

.mui--bg-color-purple-A200 {
  background-color: #E040FB !important; }

.mui--color-purple-A400 {
  color: #D500F9 !important; }

.mui--bg-color-purple-A400 {
  background-color: #D500F9 !important; }

.mui--color-purple-A700 {
  color: #AA00FF !important; }

.mui--bg-color-purple-A700 {
  background-color: #AA00FF !important; }

.mui--color-deep-purple-50 {
  color: #EDE7F6 !important; }

.mui--bg-color-deep-purple-50 {
  background-color: #EDE7F6 !important; }

.mui--color-deep-purple-100 {
  color: #D1C4E9 !important; }

.mui--bg-color-deep-purple-100 {
  background-color: #D1C4E9 !important; }

.mui--color-deep-purple-200 {
  color: #B39DDB !important; }

.mui--bg-color-deep-purple-200 {
  background-color: #B39DDB !important; }

.mui--color-deep-purple-300 {
  color: #9575CD !important; }

.mui--bg-color-deep-purple-300 {
  background-color: #9575CD !important; }

.mui--color-deep-purple-400 {
  color: #7E57C2 !important; }

.mui--bg-color-deep-purple-400 {
  background-color: #7E57C2 !important; }

.mui--color-deep-purple-500 {
  color: #673AB7 !important; }

.mui--bg-color-deep-purple-500 {
  background-color: #673AB7 !important; }

.mui--color-deep-purple {
  color: #673AB7 !important; }

.mui--bg-color-deep-purple {
  background-color: #673AB7 !important; }

.mui--color-deep-purple-600 {
  color: #5E35B1 !important; }

.mui--bg-color-deep-purple-600 {
  background-color: #5E35B1 !important; }

.mui--color-deep-purple-700 {
  color: #512DA8 !important; }

.mui--bg-color-deep-purple-700 {
  background-color: #512DA8 !important; }

.mui--color-deep-purple-800 {
  color: #4527A0 !important; }

.mui--bg-color-deep-purple-800 {
  background-color: #4527A0 !important; }

.mui--color-deep-purple-900 {
  color: #311B92 !important; }

.mui--bg-color-deep-purple-900 {
  background-color: #311B92 !important; }

.mui--color-deep-purple-A100 {
  color: #B388FF !important; }

.mui--bg-color-deep-purple-A100 {
  background-color: #B388FF !important; }

.mui--color-deep-purple-A200 {
  color: #7C4DFF !important; }

.mui--bg-color-deep-purple-A200 {
  background-color: #7C4DFF !important; }

.mui--color-deep-purple-A400 {
  color: #651FFF !important; }

.mui--bg-color-deep-purple-A400 {
  background-color: #651FFF !important; }

.mui--color-deep-purple-A700 {
  color: #6200EA !important; }

.mui--bg-color-deep-purple-A700 {
  background-color: #6200EA !important; }

.mui--color-indigo-50 {
  color: #E8EAF6 !important; }

.mui--bg-color-indigo-50 {
  background-color: #E8EAF6 !important; }

.mui--color-indigo-100 {
  color: #C5CAE9 !important; }

.mui--bg-color-indigo-100 {
  background-color: #C5CAE9 !important; }

.mui--color-indigo-200 {
  color: #9FA8DA !important; }

.mui--bg-color-indigo-200 {
  background-color: #9FA8DA !important; }

.mui--color-indigo-300 {
  color: #7986CB !important; }

.mui--bg-color-indigo-300 {
  background-color: #7986CB !important; }

.mui--color-indigo-400 {
  color: #5C6BC0 !important; }

.mui--bg-color-indigo-400 {
  background-color: #5C6BC0 !important; }

.mui--color-indigo-500 {
  color: #3F51B5 !important; }

.mui--bg-color-indigo-500 {
  background-color: #3F51B5 !important; }

.mui--color-indigo {
  color: #3F51B5 !important; }

.mui--bg-color-indigo {
  background-color: #3F51B5 !important; }

.mui--color-indigo-600 {
  color: #3949AB !important; }

.mui--bg-color-indigo-600 {
  background-color: #3949AB !important; }

.mui--color-indigo-700 {
  color: #303F9F !important; }

.mui--bg-color-indigo-700 {
  background-color: #303F9F !important; }

.mui--color-indigo-800 {
  color: #283593 !important; }

.mui--bg-color-indigo-800 {
  background-color: #283593 !important; }

.mui--color-indigo-900 {
  color: #1A237E !important; }

.mui--bg-color-indigo-900 {
  background-color: #1A237E !important; }

.mui--color-indigo-A100 {
  color: #8C9EFF !important; }

.mui--bg-color-indigo-A100 {
  background-color: #8C9EFF !important; }

.mui--color-indigo-A200 {
  color: #536DFE !important; }

.mui--bg-color-indigo-A200 {
  background-color: #536DFE !important; }

.mui--color-indigo-A400 {
  color: #3D5AFE !important; }

.mui--bg-color-indigo-A400 {
  background-color: #3D5AFE !important; }

.mui--color-indigo-A700 {
  color: #304FFE !important; }

.mui--bg-color-indigo-A700 {
  background-color: #304FFE !important; }

.mui--color-blue-50 {
  color: #E3F2FD !important; }

.mui--bg-color-blue-50 {
  background-color: #E3F2FD !important; }

.mui--color-blue-100 {
  color: #BBDEFB !important; }

.mui--bg-color-blue-100 {
  background-color: #BBDEFB !important; }

.mui--color-blue-200 {
  color: #90CAF9 !important; }

.mui--bg-color-blue-200 {
  background-color: #90CAF9 !important; }

.mui--color-blue-300 {
  color: #64B5F6 !important; }

.mui--bg-color-blue-300 {
  background-color: #64B5F6 !important; }

.mui--color-blue-400 {
  color: #42A5F5 !important; }

.mui--bg-color-blue-400 {
  background-color: #42A5F5 !important; }

.mui--color-blue-500 {
  color: #2196F3 !important; }

.mui--bg-color-blue-500 {
  background-color: #2196F3 !important; }

.mui--color-blue {
  color: #2196F3 !important; }

.mui--bg-color-blue {
  background-color: #2196F3 !important; }

.mui--color-blue-600 {
  color: #1E88E5 !important; }

.mui--bg-color-blue-600 {
  background-color: #1E88E5 !important; }

.mui--color-blue-700 {
  color: #1976D2 !important; }

.mui--bg-color-blue-700 {
  background-color: #1976D2 !important; }

.mui--color-blue-800 {
  color: #1565C0 !important; }

.mui--bg-color-blue-800 {
  background-color: #1565C0 !important; }

.mui--color-blue-900 {
  color: #0D47A1 !important; }

.mui--bg-color-blue-900 {
  background-color: #0D47A1 !important; }

.mui--color-blue-A100 {
  color: #82B1FF !important; }

.mui--bg-color-blue-A100 {
  background-color: #82B1FF !important; }

.mui--color-blue-A200 {
  color: #448AFF !important; }

.mui--bg-color-blue-A200 {
  background-color: #448AFF !important; }

.mui--color-blue-A400 {
  color: #2979FF !important; }

.mui--bg-color-blue-A400 {
  background-color: #2979FF !important; }

.mui--color-blue-A700 {
  color: #2962FF !important; }

.mui--bg-color-blue-A700 {
  background-color: #2962FF !important; }

.mui--color-light-blue-50 {
  color: #E1F5FE !important; }

.mui--bg-color-light-blue-50 {
  background-color: #E1F5FE !important; }

.mui--color-light-blue-100 {
  color: #B3E5FC !important; }

.mui--bg-color-light-blue-100 {
  background-color: #B3E5FC !important; }

.mui--color-light-blue-200 {
  color: #81D4FA !important; }

.mui--bg-color-light-blue-200 {
  background-color: #81D4FA !important; }

.mui--color-light-blue-300 {
  color: #4FC3F7 !important; }

.mui--bg-color-light-blue-300 {
  background-color: #4FC3F7 !important; }

.mui--color-light-blue-400 {
  color: #29B6F6 !important; }

.mui--bg-color-light-blue-400 {
  background-color: #29B6F6 !important; }

.mui--color-light-blue-500 {
  color: #03A9F4 !important; }

.mui--bg-color-light-blue-500 {
  background-color: #03A9F4 !important; }

.mui--color-light-blue {
  color: #03A9F4 !important; }

.mui--bg-color-light-blue {
  background-color: #03A9F4 !important; }

.mui--color-light-blue-600 {
  color: #039BE5 !important; }

.mui--bg-color-light-blue-600 {
  background-color: #039BE5 !important; }

.mui--color-light-blue-700 {
  color: #0288D1 !important; }

.mui--bg-color-light-blue-700 {
  background-color: #0288D1 !important; }

.mui--color-light-blue-800 {
  color: #0277BD !important; }

.mui--bg-color-light-blue-800 {
  background-color: #0277BD !important; }

.mui--color-light-blue-900 {
  color: #01579B !important; }

.mui--bg-color-light-blue-900 {
  background-color: #01579B !important; }

.mui--color-light-blue-A100 {
  color: #80D8FF !important; }

.mui--bg-color-light-blue-A100 {
  background-color: #80D8FF !important; }

.mui--color-light-blue-A200 {
  color: #40C4FF !important; }

.mui--bg-color-light-blue-A200 {
  background-color: #40C4FF !important; }

.mui--color-light-blue-A400 {
  color: #00B0FF !important; }

.mui--bg-color-light-blue-A400 {
  background-color: #00B0FF !important; }

.mui--color-light-blue-A700 {
  color: #0091EA !important; }

.mui--bg-color-light-blue-A700 {
  background-color: #0091EA !important; }

.mui--color-cyan-50 {
  color: #E0F7FA !important; }

.mui--bg-color-cyan-50 {
  background-color: #E0F7FA !important; }

.mui--color-cyan-100 {
  color: #B2EBF2 !important; }

.mui--bg-color-cyan-100 {
  background-color: #B2EBF2 !important; }

.mui--color-cyan-200 {
  color: #80DEEA !important; }

.mui--bg-color-cyan-200 {
  background-color: #80DEEA !important; }

.mui--color-cyan-300 {
  color: #4DD0E1 !important; }

.mui--bg-color-cyan-300 {
  background-color: #4DD0E1 !important; }

.mui--color-cyan-400 {
  color: #26C6DA !important; }

.mui--bg-color-cyan-400 {
  background-color: #26C6DA !important; }

.mui--color-cyan-500 {
  color: #00BCD4 !important; }

.mui--bg-color-cyan-500 {
  background-color: #00BCD4 !important; }

.mui--color-cyan {
  color: #00BCD4 !important; }

.mui--bg-color-cyan {
  background-color: #00BCD4 !important; }

.mui--color-cyan-600 {
  color: #00ACC1 !important; }

.mui--bg-color-cyan-600 {
  background-color: #00ACC1 !important; }

.mui--color-cyan-700 {
  color: #0097A7 !important; }

.mui--bg-color-cyan-700 {
  background-color: #0097A7 !important; }

.mui--color-cyan-800 {
  color: #00838F !important; }

.mui--bg-color-cyan-800 {
  background-color: #00838F !important; }

.mui--color-cyan-900 {
  color: #006064 !important; }

.mui--bg-color-cyan-900 {
  background-color: #006064 !important; }

.mui--color-cyan-A100 {
  color: #84FFFF !important; }

.mui--bg-color-cyan-A100 {
  background-color: #84FFFF !important; }

.mui--color-cyan-A200 {
  color: #18FFFF !important; }

.mui--bg-color-cyan-A200 {
  background-color: #18FFFF !important; }

.mui--color-cyan-A400 {
  color: #00E5FF !important; }

.mui--bg-color-cyan-A400 {
  background-color: #00E5FF !important; }

.mui--color-cyan-A700 {
  color: #00B8D4 !important; }

.mui--bg-color-cyan-A700 {
  background-color: #00B8D4 !important; }

.mui--color-teal-50 {
  color: #E0F2F1 !important; }

.mui--bg-color-teal-50 {
  background-color: #E0F2F1 !important; }

.mui--color-teal-100 {
  color: #B2DFDB !important; }

.mui--bg-color-teal-100 {
  background-color: #B2DFDB !important; }

.mui--color-teal-200 {
  color: #80CBC4 !important; }

.mui--bg-color-teal-200 {
  background-color: #80CBC4 !important; }

.mui--color-teal-300 {
  color: #4DB6AC !important; }

.mui--bg-color-teal-300 {
  background-color: #4DB6AC !important; }

.mui--color-teal-400 {
  color: #26A69A !important; }

.mui--bg-color-teal-400 {
  background-color: #26A69A !important; }

.mui--color-teal-500 {
  color: #009688 !important; }

.mui--bg-color-teal-500 {
  background-color: #009688 !important; }

.mui--color-teal {
  color: #009688 !important; }

.mui--bg-color-teal {
  background-color: #009688 !important; }

.mui--color-teal-600 {
  color: #00897B !important; }

.mui--bg-color-teal-600 {
  background-color: #00897B !important; }

.mui--color-teal-700 {
  color: #00796B !important; }

.mui--bg-color-teal-700 {
  background-color: #00796B !important; }

.mui--color-teal-800 {
  color: #00695C !important; }

.mui--bg-color-teal-800 {
  background-color: #00695C !important; }

.mui--color-teal-900 {
  color: #004D40 !important; }

.mui--bg-color-teal-900 {
  background-color: #004D40 !important; }

.mui--color-teal-A100 {
  color: #A7FFEB !important; }

.mui--bg-color-teal-A100 {
  background-color: #A7FFEB !important; }

.mui--color-teal-A200 {
  color: #64FFDA !important; }

.mui--bg-color-teal-A200 {
  background-color: #64FFDA !important; }

.mui--color-teal-A400 {
  color: #1DE9B6 !important; }

.mui--bg-color-teal-A400 {
  background-color: #1DE9B6 !important; }

.mui--color-teal-A700 {
  color: #00BFA5 !important; }

.mui--bg-color-teal-A700 {
  background-color: #00BFA5 !important; }

.mui--color-green-50 {
  color: #E8F5E9 !important; }

.mui--bg-color-green-50 {
  background-color: #E8F5E9 !important; }

.mui--color-green-100 {
  color: #C8E6C9 !important; }

.mui--bg-color-green-100 {
  background-color: #C8E6C9 !important; }

.mui--color-green-200 {
  color: #A5D6A7 !important; }

.mui--bg-color-green-200 {
  background-color: #A5D6A7 !important; }

.mui--color-green-300 {
  color: #81C784 !important; }

.mui--bg-color-green-300 {
  background-color: #81C784 !important; }

.mui--color-green-400 {
  color: #66BB6A !important; }

.mui--bg-color-green-400 {
  background-color: #66BB6A !important; }

.mui--color-green-500 {
  color: #4CAF50 !important; }

.mui--bg-color-green-500 {
  background-color: #4CAF50 !important; }

.mui--color-green {
  color: #4CAF50 !important; }

.mui--bg-color-green {
  background-color: #4CAF50 !important; }

.mui--color-green-600 {
  color: #43A047 !important; }

.mui--bg-color-green-600 {
  background-color: #43A047 !important; }

.mui--color-green-700 {
  color: #388E3C !important; }

.mui--bg-color-green-700 {
  background-color: #388E3C !important; }

.mui--color-green-800 {
  color: #2E7D32 !important; }

.mui--bg-color-green-800 {
  background-color: #2E7D32 !important; }

.mui--color-green-900 {
  color: #1B5E20 !important; }

.mui--bg-color-green-900 {
  background-color: #1B5E20 !important; }

.mui--color-green-A100 {
  color: #B9F6CA !important; }

.mui--bg-color-green-A100 {
  background-color: #B9F6CA !important; }

.mui--color-green-A200 {
  color: #69F0AE !important; }

.mui--bg-color-green-A200 {
  background-color: #69F0AE !important; }

.mui--color-green-A400 {
  color: #00E676 !important; }

.mui--bg-color-green-A400 {
  background-color: #00E676 !important; }

.mui--color-green-A700 {
  color: #00C853 !important; }

.mui--bg-color-green-A700 {
  background-color: #00C853 !important; }

.mui--color-light-green-50 {
  color: #F1F8E9 !important; }

.mui--bg-color-light-green-50 {
  background-color: #F1F8E9 !important; }

.mui--color-light-green-100 {
  color: #DCEDC8 !important; }

.mui--bg-color-light-green-100 {
  background-color: #DCEDC8 !important; }

.mui--color-light-green-200 {
  color: #C5E1A5 !important; }

.mui--bg-color-light-green-200 {
  background-color: #C5E1A5 !important; }

.mui--color-light-green-300 {
  color: #AED581 !important; }

.mui--bg-color-light-green-300 {
  background-color: #AED581 !important; }

.mui--color-light-green-400 {
  color: #9CCC65 !important; }

.mui--bg-color-light-green-400 {
  background-color: #9CCC65 !important; }

.mui--color-light-green-500 {
  color: #8BC34A !important; }

.mui--bg-color-light-green-500 {
  background-color: #8BC34A !important; }

.mui--color-light-green {
  color: #8BC34A !important; }

.mui--bg-color-light-green {
  background-color: #8BC34A !important; }

.mui--color-light-green-600 {
  color: #7CB342 !important; }

.mui--bg-color-light-green-600 {
  background-color: #7CB342 !important; }

.mui--color-light-green-700 {
  color: #689F38 !important; }

.mui--bg-color-light-green-700 {
  background-color: #689F38 !important; }

.mui--color-light-green-800 {
  color: #558B2F !important; }

.mui--bg-color-light-green-800 {
  background-color: #558B2F !important; }

.mui--color-light-green-900 {
  color: #33691E !important; }

.mui--bg-color-light-green-900 {
  background-color: #33691E !important; }

.mui--color-light-green-A100 {
  color: #CCFF90 !important; }

.mui--bg-color-light-green-A100 {
  background-color: #CCFF90 !important; }

.mui--color-light-green-A200 {
  color: #B2FF59 !important; }

.mui--bg-color-light-green-A200 {
  background-color: #B2FF59 !important; }

.mui--color-light-green-A400 {
  color: #76FF03 !important; }

.mui--bg-color-light-green-A400 {
  background-color: #76FF03 !important; }

.mui--color-light-green-A700 {
  color: #64DD17 !important; }

.mui--bg-color-light-green-A700 {
  background-color: #64DD17 !important; }

.mui--color-lime-50 {
  color: #F9FBE7 !important; }

.mui--bg-color-lime-50 {
  background-color: #F9FBE7 !important; }

.mui--color-lime-100 {
  color: #F0F4C3 !important; }

.mui--bg-color-lime-100 {
  background-color: #F0F4C3 !important; }

.mui--color-lime-200 {
  color: #E6EE9C !important; }

.mui--bg-color-lime-200 {
  background-color: #E6EE9C !important; }

.mui--color-lime-300 {
  color: #DCE775 !important; }

.mui--bg-color-lime-300 {
  background-color: #DCE775 !important; }

.mui--color-lime-400 {
  color: #D4E157 !important; }

.mui--bg-color-lime-400 {
  background-color: #D4E157 !important; }

.mui--color-lime-500 {
  color: #CDDC39 !important; }

.mui--bg-color-lime-500 {
  background-color: #CDDC39 !important; }

.mui--color-lime {
  color: #CDDC39 !important; }

.mui--bg-color-lime {
  background-color: #CDDC39 !important; }

.mui--color-lime-600 {
  color: #C0CA33 !important; }

.mui--bg-color-lime-600 {
  background-color: #C0CA33 !important; }

.mui--color-lime-700 {
  color: #AFB42B !important; }

.mui--bg-color-lime-700 {
  background-color: #AFB42B !important; }

.mui--color-lime-800 {
  color: #9E9D24 !important; }

.mui--bg-color-lime-800 {
  background-color: #9E9D24 !important; }

.mui--color-lime-900 {
  color: #827717 !important; }

.mui--bg-color-lime-900 {
  background-color: #827717 !important; }

.mui--color-lime-A100 {
  color: #F4FF81 !important; }

.mui--bg-color-lime-A100 {
  background-color: #F4FF81 !important; }

.mui--color-lime-A200 {
  color: #EEFF41 !important; }

.mui--bg-color-lime-A200 {
  background-color: #EEFF41 !important; }

.mui--color-lime-A400 {
  color: #C6FF00 !important; }

.mui--bg-color-lime-A400 {
  background-color: #C6FF00 !important; }

.mui--color-lime-A700 {
  color: #AEEA00 !important; }

.mui--bg-color-lime-A700 {
  background-color: #AEEA00 !important; }

.mui--color-yellow-50 {
  color: #FFFDE7 !important; }

.mui--bg-color-yellow-50 {
  background-color: #FFFDE7 !important; }

.mui--color-yellow-100 {
  color: #FFF9C4 !important; }

.mui--bg-color-yellow-100 {
  background-color: #FFF9C4 !important; }

.mui--color-yellow-200 {
  color: #FFF59D !important; }

.mui--bg-color-yellow-200 {
  background-color: #FFF59D !important; }

.mui--color-yellow-300 {
  color: #FFF176 !important; }

.mui--bg-color-yellow-300 {
  background-color: #FFF176 !important; }

.mui--color-yellow-400 {
  color: #FFEE58 !important; }

.mui--bg-color-yellow-400 {
  background-color: #FFEE58 !important; }

.mui--color-yellow-500 {
  color: #FFEB3B !important; }

.mui--bg-color-yellow-500 {
  background-color: #FFEB3B !important; }

.mui--color-yellow {
  color: #FFEB3B !important; }

.mui--bg-color-yellow {
  background-color: #FFEB3B !important; }

.mui--color-yellow-600 {
  color: #FDD835 !important; }

.mui--bg-color-yellow-600 {
  background-color: #FDD835 !important; }

.mui--color-yellow-700 {
  color: #FBC02D !important; }

.mui--bg-color-yellow-700 {
  background-color: #FBC02D !important; }

.mui--color-yellow-800 {
  color: #F9A825 !important; }

.mui--bg-color-yellow-800 {
  background-color: #F9A825 !important; }

.mui--color-yellow-900 {
  color: #F57F17 !important; }

.mui--bg-color-yellow-900 {
  background-color: #F57F17 !important; }

.mui--color-yellow-A100 {
  color: #FFFF8D !important; }

.mui--bg-color-yellow-A100 {
  background-color: #FFFF8D !important; }

.mui--color-yellow-A200 {
  color: #FFFF00 !important; }

.mui--bg-color-yellow-A200 {
  background-color: #FFFF00 !important; }

.mui--color-yellow-A400 {
  color: #FFEA00 !important; }

.mui--bg-color-yellow-A400 {
  background-color: #FFEA00 !important; }

.mui--color-yellow-A700 {
  color: #FFD600 !important; }

.mui--bg-color-yellow-A700 {
  background-color: #FFD600 !important; }

.mui--color-amber-50 {
  color: #FFF8E1 !important; }

.mui--bg-color-amber-50 {
  background-color: #FFF8E1 !important; }

.mui--color-amber-100 {
  color: #FFECB3 !important; }

.mui--bg-color-amber-100 {
  background-color: #FFECB3 !important; }

.mui--color-amber-200 {
  color: #FFE082 !important; }

.mui--bg-color-amber-200 {
  background-color: #FFE082 !important; }

.mui--color-amber-300 {
  color: #FFD54F !important; }

.mui--bg-color-amber-300 {
  background-color: #FFD54F !important; }

.mui--color-amber-400 {
  color: #FFCA28 !important; }

.mui--bg-color-amber-400 {
  background-color: #FFCA28 !important; }

.mui--color-amber-500 {
  color: #FFC107 !important; }

.mui--bg-color-amber-500 {
  background-color: #FFC107 !important; }

.mui--color-amber {
  color: #FFC107 !important; }

.mui--bg-color-amber {
  background-color: #FFC107 !important; }

.mui--color-amber-600 {
  color: #FFB300 !important; }

.mui--bg-color-amber-600 {
  background-color: #FFB300 !important; }

.mui--color-amber-700 {
  color: #FFA000 !important; }

.mui--bg-color-amber-700 {
  background-color: #FFA000 !important; }

.mui--color-amber-800 {
  color: #FF8F00 !important; }

.mui--bg-color-amber-800 {
  background-color: #FF8F00 !important; }

.mui--color-amber-900 {
  color: #FF6F00 !important; }

.mui--bg-color-amber-900 {
  background-color: #FF6F00 !important; }

.mui--color-amber-A100 {
  color: #FFE57F !important; }

.mui--bg-color-amber-A100 {
  background-color: #FFE57F !important; }

.mui--color-amber-A200 {
  color: #FFD740 !important; }

.mui--bg-color-amber-A200 {
  background-color: #FFD740 !important; }

.mui--color-amber-A400 {
  color: #FFC400 !important; }

.mui--bg-color-amber-A400 {
  background-color: #FFC400 !important; }

.mui--color-amber-A700 {
  color: #FFAB00 !important; }

.mui--bg-color-amber-A700 {
  background-color: #FFAB00 !important; }

.mui--color-orange-50 {
  color: #FFF3E0 !important; }

.mui--bg-color-orange-50 {
  background-color: #FFF3E0 !important; }

.mui--color-orange-100 {
  color: #FFE0B2 !important; }

.mui--bg-color-orange-100 {
  background-color: #FFE0B2 !important; }

.mui--color-orange-200 {
  color: #FFCC80 !important; }

.mui--bg-color-orange-200 {
  background-color: #FFCC80 !important; }

.mui--color-orange-300 {
  color: #FFB74D !important; }

.mui--bg-color-orange-300 {
  background-color: #FFB74D !important; }

.mui--color-orange-400 {
  color: #FFA726 !important; }

.mui--bg-color-orange-400 {
  background-color: #FFA726 !important; }

.mui--color-orange-500 {
  color: #FF9800 !important; }

.mui--bg-color-orange-500 {
  background-color: #FF9800 !important; }

.mui--color-orange {
  color: #FF9800 !important; }

.mui--bg-color-orange {
  background-color: #FF9800 !important; }

.mui--color-orange-600 {
  color: #FB8C00 !important; }

.mui--bg-color-orange-600 {
  background-color: #FB8C00 !important; }

.mui--color-orange-700 {
  color: #F57C00 !important; }

.mui--bg-color-orange-700 {
  background-color: #F57C00 !important; }

.mui--color-orange-800 {
  color: #EF6C00 !important; }

.mui--bg-color-orange-800 {
  background-color: #EF6C00 !important; }

.mui--color-orange-900 {
  color: #E65100 !important; }

.mui--bg-color-orange-900 {
  background-color: #E65100 !important; }

.mui--color-orange-A100 {
  color: #FFD180 !important; }

.mui--bg-color-orange-A100 {
  background-color: #FFD180 !important; }

.mui--color-orange-A200 {
  color: #FFAB40 !important; }

.mui--bg-color-orange-A200 {
  background-color: #FFAB40 !important; }

.mui--color-orange-A400 {
  color: #FF9100 !important; }

.mui--bg-color-orange-A400 {
  background-color: #FF9100 !important; }

.mui--color-orange-A700 {
  color: #FF6D00 !important; }

.mui--bg-color-orange-A700 {
  background-color: #FF6D00 !important; }

.mui--color-deep-orange-50 {
  color: #FBE9E7 !important; }

.mui--bg-color-deep-orange-50 {
  background-color: #FBE9E7 !important; }

.mui--color-deep-orange-100 {
  color: #FFCCBC !important; }

.mui--bg-color-deep-orange-100 {
  background-color: #FFCCBC !important; }

.mui--color-deep-orange-200 {
  color: #FFAB91 !important; }

.mui--bg-color-deep-orange-200 {
  background-color: #FFAB91 !important; }

.mui--color-deep-orange-300 {
  color: #FF8A65 !important; }

.mui--bg-color-deep-orange-300 {
  background-color: #FF8A65 !important; }

.mui--color-deep-orange-400 {
  color: #FF7043 !important; }

.mui--bg-color-deep-orange-400 {
  background-color: #FF7043 !important; }

.mui--color-deep-orange-500 {
  color: #FF5722 !important; }

.mui--bg-color-deep-orange-500 {
  background-color: #FF5722 !important; }

.mui--color-deep-orange {
  color: #FF5722 !important; }

.mui--bg-color-deep-orange {
  background-color: #FF5722 !important; }

.mui--color-deep-orange-600 {
  color: #F4511E !important; }

.mui--bg-color-deep-orange-600 {
  background-color: #F4511E !important; }

.mui--color-deep-orange-700 {
  color: #E64A19 !important; }

.mui--bg-color-deep-orange-700 {
  background-color: #E64A19 !important; }

.mui--color-deep-orange-800 {
  color: #D84315 !important; }

.mui--bg-color-deep-orange-800 {
  background-color: #D84315 !important; }

.mui--color-deep-orange-900 {
  color: #BF360C !important; }

.mui--bg-color-deep-orange-900 {
  background-color: #BF360C !important; }

.mui--color-deep-orange-A100 {
  color: #FF9E80 !important; }

.mui--bg-color-deep-orange-A100 {
  background-color: #FF9E80 !important; }

.mui--color-deep-orange-A200 {
  color: #FF6E40 !important; }

.mui--bg-color-deep-orange-A200 {
  background-color: #FF6E40 !important; }

.mui--color-deep-orange-A400 {
  color: #FF3D00 !important; }

.mui--bg-color-deep-orange-A400 {
  background-color: #FF3D00 !important; }

.mui--color-deep-orange-A700 {
  color: #DD2C00 !important; }

.mui--bg-color-deep-orange-A700 {
  background-color: #DD2C00 !important; }

.mui--color-brown-50 {
  color: #EFEBE9 !important; }

.mui--bg-color-brown-50 {
  background-color: #EFEBE9 !important; }

.mui--color-brown-100 {
  color: #D7CCC8 !important; }

.mui--bg-color-brown-100 {
  background-color: #D7CCC8 !important; }

.mui--color-brown-200 {
  color: #BCAAA4 !important; }

.mui--bg-color-brown-200 {
  background-color: #BCAAA4 !important; }

.mui--color-brown-300 {
  color: #A1887F !important; }

.mui--bg-color-brown-300 {
  background-color: #A1887F !important; }

.mui--color-brown-400 {
  color: #8D6E63 !important; }

.mui--bg-color-brown-400 {
  background-color: #8D6E63 !important; }

.mui--color-brown-500 {
  color: #795548 !important; }

.mui--bg-color-brown-500 {
  background-color: #795548 !important; }

.mui--color-brown {
  color: #795548 !important; }

.mui--bg-color-brown {
  background-color: #795548 !important; }

.mui--color-brown-600 {
  color: #6D4C41 !important; }

.mui--bg-color-brown-600 {
  background-color: #6D4C41 !important; }

.mui--color-brown-700 {
  color: #5D4037 !important; }

.mui--bg-color-brown-700 {
  background-color: #5D4037 !important; }

.mui--color-brown-800 {
  color: #4E342E !important; }

.mui--bg-color-brown-800 {
  background-color: #4E342E !important; }

.mui--color-brown-900 {
  color: #3E2723 !important; }

.mui--bg-color-brown-900 {
  background-color: #3E2723 !important; }

.mui--color-grey-50 {
  color: #FAFAFA !important; }

.mui--bg-color-grey-50 {
  background-color: #FAFAFA !important; }

.mui--color-grey-100 {
  color: #F5F5F5 !important; }

.mui--bg-color-grey-100 {
  background-color: #F5F5F5 !important; }

.mui--color-grey-200 {
  color: #EEEEEE !important; }

.mui--bg-color-grey-200 {
  background-color: #EEEEEE !important; }

.mui--color-grey-300 {
  color: #E0E0E0 !important; }

.mui--bg-color-grey-300 {
  background-color: #E0E0E0 !important; }

.mui--color-grey-400 {
  color: #BDBDBD !important; }

.mui--bg-color-grey-400 {
  background-color: #BDBDBD !important; }

.mui--color-grey-500 {
  color: #9E9E9E !important; }

.mui--bg-color-grey-500 {
  background-color: #9E9E9E !important; }

.mui--color-grey {
  color: #9E9E9E !important; }

.mui--bg-color-grey {
  background-color: #9E9E9E !important; }

.mui--color-grey-600 {
  color: #757575 !important; }

.mui--bg-color-grey-600 {
  background-color: #757575 !important; }

.mui--color-grey-700 {
  color: #616161 !important; }

.mui--bg-color-grey-700 {
  background-color: #616161 !important; }

.mui--color-grey-800 {
  color: #424242 !important; }

.mui--bg-color-grey-800 {
  background-color: #424242 !important; }

.mui--color-grey-900 {
  color: #212121 !important; }

.mui--bg-color-grey-900 {
  background-color: #212121 !important; }

.mui--color-blue-grey-50 {
  color: #ECEFF1 !important; }

.mui--bg-color-blue-grey-50 {
  background-color: #ECEFF1 !important; }

.mui--color-blue-grey-100 {
  color: #CFD8DC !important; }

.mui--bg-color-blue-grey-100 {
  background-color: #CFD8DC !important; }

.mui--color-blue-grey-200 {
  color: #B0BEC5 !important; }

.mui--bg-color-blue-grey-200 {
  background-color: #B0BEC5 !important; }

.mui--color-blue-grey-300 {
  color: #90A4AE !important; }

.mui--bg-color-blue-grey-300 {
  background-color: #90A4AE !important; }

.mui--color-blue-grey-400 {
  color: #78909C !important; }

.mui--bg-color-blue-grey-400 {
  background-color: #78909C !important; }

.mui--color-blue-grey-500 {
  color: #607D8B !important; }

.mui--bg-color-blue-grey-500 {
  background-color: #607D8B !important; }

.mui--color-blue-grey {
  color: #607D8B !important; }

.mui--bg-color-blue-grey {
  background-color: #607D8B !important; }

.mui--color-blue-grey-600 {
  color: #546E7A !important; }

.mui--bg-color-blue-grey-600 {
  background-color: #546E7A !important; }

.mui--color-blue-grey-700 {
  color: #455A64 !important; }

.mui--bg-color-blue-grey-700 {
  background-color: #455A64 !important; }

.mui--color-blue-grey-800 {
  color: #37474F !important; }

.mui--bg-color-blue-grey-800 {
  background-color: #37474F !important; }

.mui--color-blue-grey-900 {
  color: #263238 !important; }

.mui--bg-color-blue-grey-900 {
  background-color: #263238 !important; }

.mui--color-black {
  color: #000 !important; }

.mui--bg-color-black {
  background-color: #000 !important; }

.mui--color-white {
  color: #FFF !important; }

.mui--bg-color-white {
  background-color: #FFF !important; }

.mui--color-black-alpha-12 {
  color: rgba(0, 0, 0, 0.12) !important; }

.mui--bg-color-black-alpha-12 {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.mui--color-black-alpha-38 {
  color: rgba(0, 0, 0, 0.38) !important; }

.mui--bg-color-black-alpha-38 {
  background-color: rgba(0, 0, 0, 0.38) !important; }

.mui--color-black-alpha-54 {
  color: rgba(0, 0, 0, 0.54) !important; }

.mui--bg-color-black-alpha-54 {
  background-color: rgba(0, 0, 0, 0.54) !important; }

.mui--color-black-alpha-87 {
  color: rgba(0, 0, 0, 0.87) !important; }

.mui--bg-color-black-alpha-87 {
  background-color: rgba(0, 0, 0, 0.87) !important; }

.mui--color-white-alpha-12 {
  color: rgba(255, 255, 255, 0.12) !important; }

.mui--bg-color-white-alpha-12 {
  background-color: rgba(255, 255, 255, 0.12) !important; }

.mui--color-white-alpha-30 {
  color: rgba(255, 255, 255, 0.3) !important; }

.mui--bg-color-white-alpha-30 {
  background-color: rgba(255, 255, 255, 0.3) !important; }

.mui--color-white-alpha-70 {
  color: rgba(255, 255, 255, 0.7) !important; }

.mui--bg-color-white-alpha-70 {
  background-color: rgba(255, 255, 255, 0.7) !important; }

/**
 * MUI Colors module
 */
.mui--color-red-50 {
  color: #FFEBEE !important; }

.mui--bg-color-red-50 {
  background-color: #FFEBEE !important; }

.mui--color-red-100 {
  color: #FFCDD2 !important; }

.mui--bg-color-red-100 {
  background-color: #FFCDD2 !important; }

.mui--color-red-200 {
  color: #EF9A9A !important; }

.mui--bg-color-red-200 {
  background-color: #EF9A9A !important; }

.mui--color-red-300 {
  color: #E57373 !important; }

.mui--bg-color-red-300 {
  background-color: #E57373 !important; }

.mui--color-red-400 {
  color: #EF5350 !important; }

.mui--bg-color-red-400 {
  background-color: #EF5350 !important; }

.mui--color-red-500 {
  color: #F44336 !important; }

.mui--bg-color-red-500 {
  background-color: #F44336 !important; }

.mui--color-red {
  color: #F44336 !important; }

.mui--bg-color-red {
  background-color: #F44336 !important; }

.mui--color-red-600 {
  color: #E53935 !important; }

.mui--bg-color-red-600 {
  background-color: #E53935 !important; }

.mui--color-red-700 {
  color: #D32F2F !important; }

.mui--bg-color-red-700 {
  background-color: #D32F2F !important; }

.mui--color-red-800 {
  color: #C62828 !important; }

.mui--bg-color-red-800 {
  background-color: #C62828 !important; }

.mui--color-red-900 {
  color: #B71C1C !important; }

.mui--bg-color-red-900 {
  background-color: #B71C1C !important; }

.mui--color-red-A100 {
  color: #FF8A80 !important; }

.mui--bg-color-red-A100 {
  background-color: #FF8A80 !important; }

.mui--color-red-A200 {
  color: #FF5252 !important; }

.mui--bg-color-red-A200 {
  background-color: #FF5252 !important; }

.mui--color-red-A400 {
  color: #FF1744 !important; }

.mui--bg-color-red-A400 {
  background-color: #FF1744 !important; }

.mui--color-red-A700 {
  color: #D50000 !important; }

.mui--bg-color-red-A700 {
  background-color: #D50000 !important; }

.mui--color-pink-50 {
  color: #FCE4EC !important; }

.mui--bg-color-pink-50 {
  background-color: #FCE4EC !important; }

.mui--color-pink-100 {
  color: #F8BBD0 !important; }

.mui--bg-color-pink-100 {
  background-color: #F8BBD0 !important; }

.mui--color-pink-200 {
  color: #F48FB1 !important; }

.mui--bg-color-pink-200 {
  background-color: #F48FB1 !important; }

.mui--color-pink-300 {
  color: #F06292 !important; }

.mui--bg-color-pink-300 {
  background-color: #F06292 !important; }

.mui--color-pink-400 {
  color: #EC407A !important; }

.mui--bg-color-pink-400 {
  background-color: #EC407A !important; }

.mui--color-pink-500 {
  color: #E91E63 !important; }

.mui--bg-color-pink-500 {
  background-color: #E91E63 !important; }

.mui--color-pink {
  color: #E91E63 !important; }

.mui--bg-color-pink {
  background-color: #E91E63 !important; }

.mui--color-pink-600 {
  color: #D81B60 !important; }

.mui--bg-color-pink-600 {
  background-color: #D81B60 !important; }

.mui--color-pink-700 {
  color: #C2185B !important; }

.mui--bg-color-pink-700 {
  background-color: #C2185B !important; }

.mui--color-pink-800 {
  color: #AD1457 !important; }

.mui--bg-color-pink-800 {
  background-color: #AD1457 !important; }

.mui--color-pink-900 {
  color: #880E4F !important; }

.mui--bg-color-pink-900 {
  background-color: #880E4F !important; }

.mui--color-pink-A100 {
  color: #FF80AB !important; }

.mui--bg-color-pink-A100 {
  background-color: #FF80AB !important; }

.mui--color-pink-A200 {
  color: #FF4081 !important; }

.mui--bg-color-pink-A200 {
  background-color: #FF4081 !important; }

.mui--color-pink-A400 {
  color: #F50057 !important; }

.mui--bg-color-pink-A400 {
  background-color: #F50057 !important; }

.mui--color-pink-A700 {
  color: #C51162 !important; }

.mui--bg-color-pink-A700 {
  background-color: #C51162 !important; }

.mui--color-purple-50 {
  color: #F3E5F5 !important; }

.mui--bg-color-purple-50 {
  background-color: #F3E5F5 !important; }

.mui--color-purple-100 {
  color: #E1BEE7 !important; }

.mui--bg-color-purple-100 {
  background-color: #E1BEE7 !important; }

.mui--color-purple-200 {
  color: #CE93D8 !important; }

.mui--bg-color-purple-200 {
  background-color: #CE93D8 !important; }

.mui--color-purple-300 {
  color: #BA68C8 !important; }

.mui--bg-color-purple-300 {
  background-color: #BA68C8 !important; }

.mui--color-purple-400 {
  color: #AB47BC !important; }

.mui--bg-color-purple-400 {
  background-color: #AB47BC !important; }

.mui--color-purple-500 {
  color: #9C27B0 !important; }

.mui--bg-color-purple-500 {
  background-color: #9C27B0 !important; }

.mui--color-purple {
  color: #9C27B0 !important; }

.mui--bg-color-purple {
  background-color: #9C27B0 !important; }

.mui--color-purple-600 {
  color: #8E24AA !important; }

.mui--bg-color-purple-600 {
  background-color: #8E24AA !important; }

.mui--color-purple-700 {
  color: #7B1FA2 !important; }

.mui--bg-color-purple-700 {
  background-color: #7B1FA2 !important; }

.mui--color-purple-800 {
  color: #6A1B9A !important; }

.mui--bg-color-purple-800 {
  background-color: #6A1B9A !important; }

.mui--color-purple-900 {
  color: #4A148C !important; }

.mui--bg-color-purple-900 {
  background-color: #4A148C !important; }

.mui--color-purple-A100 {
  color: #EA80FC !important; }

.mui--bg-color-purple-A100 {
  background-color: #EA80FC !important; }

.mui--color-purple-A200 {
  color: #E040FB !important; }

.mui--bg-color-purple-A200 {
  background-color: #E040FB !important; }

.mui--color-purple-A400 {
  color: #D500F9 !important; }

.mui--bg-color-purple-A400 {
  background-color: #D500F9 !important; }

.mui--color-purple-A700 {
  color: #AA00FF !important; }

.mui--bg-color-purple-A700 {
  background-color: #AA00FF !important; }

.mui--color-deep-purple-50 {
  color: #EDE7F6 !important; }

.mui--bg-color-deep-purple-50 {
  background-color: #EDE7F6 !important; }

.mui--color-deep-purple-100 {
  color: #D1C4E9 !important; }

.mui--bg-color-deep-purple-100 {
  background-color: #D1C4E9 !important; }

.mui--color-deep-purple-200 {
  color: #B39DDB !important; }

.mui--bg-color-deep-purple-200 {
  background-color: #B39DDB !important; }

.mui--color-deep-purple-300 {
  color: #9575CD !important; }

.mui--bg-color-deep-purple-300 {
  background-color: #9575CD !important; }

.mui--color-deep-purple-400 {
  color: #7E57C2 !important; }

.mui--bg-color-deep-purple-400 {
  background-color: #7E57C2 !important; }

.mui--color-deep-purple-500 {
  color: #673AB7 !important; }

.mui--bg-color-deep-purple-500 {
  background-color: #673AB7 !important; }

.mui--color-deep-purple {
  color: #673AB7 !important; }

.mui--bg-color-deep-purple {
  background-color: #673AB7 !important; }

.mui--color-deep-purple-600 {
  color: #5E35B1 !important; }

.mui--bg-color-deep-purple-600 {
  background-color: #5E35B1 !important; }

.mui--color-deep-purple-700 {
  color: #512DA8 !important; }

.mui--bg-color-deep-purple-700 {
  background-color: #512DA8 !important; }

.mui--color-deep-purple-800 {
  color: #4527A0 !important; }

.mui--bg-color-deep-purple-800 {
  background-color: #4527A0 !important; }

.mui--color-deep-purple-900 {
  color: #311B92 !important; }

.mui--bg-color-deep-purple-900 {
  background-color: #311B92 !important; }

.mui--color-deep-purple-A100 {
  color: #B388FF !important; }

.mui--bg-color-deep-purple-A100 {
  background-color: #B388FF !important; }

.mui--color-deep-purple-A200 {
  color: #7C4DFF !important; }

.mui--bg-color-deep-purple-A200 {
  background-color: #7C4DFF !important; }

.mui--color-deep-purple-A400 {
  color: #651FFF !important; }

.mui--bg-color-deep-purple-A400 {
  background-color: #651FFF !important; }

.mui--color-deep-purple-A700 {
  color: #6200EA !important; }

.mui--bg-color-deep-purple-A700 {
  background-color: #6200EA !important; }

.mui--color-indigo-50 {
  color: #E8EAF6 !important; }

.mui--bg-color-indigo-50 {
  background-color: #E8EAF6 !important; }

.mui--color-indigo-100 {
  color: #C5CAE9 !important; }

.mui--bg-color-indigo-100 {
  background-color: #C5CAE9 !important; }

.mui--color-indigo-200 {
  color: #9FA8DA !important; }

.mui--bg-color-indigo-200 {
  background-color: #9FA8DA !important; }

.mui--color-indigo-300 {
  color: #7986CB !important; }

.mui--bg-color-indigo-300 {
  background-color: #7986CB !important; }

.mui--color-indigo-400 {
  color: #5C6BC0 !important; }

.mui--bg-color-indigo-400 {
  background-color: #5C6BC0 !important; }

.mui--color-indigo-500 {
  color: #3F51B5 !important; }

.mui--bg-color-indigo-500 {
  background-color: #3F51B5 !important; }

.mui--color-indigo {
  color: #3F51B5 !important; }

.mui--bg-color-indigo {
  background-color: #3F51B5 !important; }

.mui--color-indigo-600 {
  color: #3949AB !important; }

.mui--bg-color-indigo-600 {
  background-color: #3949AB !important; }

.mui--color-indigo-700 {
  color: #303F9F !important; }

.mui--bg-color-indigo-700 {
  background-color: #303F9F !important; }

.mui--color-indigo-800 {
  color: #283593 !important; }

.mui--bg-color-indigo-800 {
  background-color: #283593 !important; }

.mui--color-indigo-900 {
  color: #1A237E !important; }

.mui--bg-color-indigo-900 {
  background-color: #1A237E !important; }

.mui--color-indigo-A100 {
  color: #8C9EFF !important; }

.mui--bg-color-indigo-A100 {
  background-color: #8C9EFF !important; }

.mui--color-indigo-A200 {
  color: #536DFE !important; }

.mui--bg-color-indigo-A200 {
  background-color: #536DFE !important; }

.mui--color-indigo-A400 {
  color: #3D5AFE !important; }

.mui--bg-color-indigo-A400 {
  background-color: #3D5AFE !important; }

.mui--color-indigo-A700 {
  color: #304FFE !important; }

.mui--bg-color-indigo-A700 {
  background-color: #304FFE !important; }

.mui--color-blue-50 {
  color: #E3F2FD !important; }

.mui--bg-color-blue-50 {
  background-color: #E3F2FD !important; }

.mui--color-blue-100 {
  color: #BBDEFB !important; }

.mui--bg-color-blue-100 {
  background-color: #BBDEFB !important; }

.mui--color-blue-200 {
  color: #90CAF9 !important; }

.mui--bg-color-blue-200 {
  background-color: #90CAF9 !important; }

.mui--color-blue-300 {
  color: #64B5F6 !important; }

.mui--bg-color-blue-300 {
  background-color: #64B5F6 !important; }

.mui--color-blue-400 {
  color: #42A5F5 !important; }

.mui--bg-color-blue-400 {
  background-color: #42A5F5 !important; }

.mui--color-blue-500 {
  color: #2196F3 !important; }

.mui--bg-color-blue-500 {
  background-color: #2196F3 !important; }

.mui--color-blue {
  color: #2196F3 !important; }

.mui--bg-color-blue {
  background-color: #2196F3 !important; }

.mui--color-blue-600 {
  color: #1E88E5 !important; }

.mui--bg-color-blue-600 {
  background-color: #1E88E5 !important; }

.mui--color-blue-700 {
  color: #1976D2 !important; }

.mui--bg-color-blue-700 {
  background-color: #1976D2 !important; }

.mui--color-blue-800 {
  color: #1565C0 !important; }

.mui--bg-color-blue-800 {
  background-color: #1565C0 !important; }

.mui--color-blue-900 {
  color: #0D47A1 !important; }

.mui--bg-color-blue-900 {
  background-color: #0D47A1 !important; }

.mui--color-blue-A100 {
  color: #82B1FF !important; }

.mui--bg-color-blue-A100 {
  background-color: #82B1FF !important; }

.mui--color-blue-A200 {
  color: #448AFF !important; }

.mui--bg-color-blue-A200 {
  background-color: #448AFF !important; }

.mui--color-blue-A400 {
  color: #2979FF !important; }

.mui--bg-color-blue-A400 {
  background-color: #2979FF !important; }

.mui--color-blue-A700 {
  color: #2962FF !important; }

.mui--bg-color-blue-A700 {
  background-color: #2962FF !important; }

.mui--color-light-blue-50 {
  color: #E1F5FE !important; }

.mui--bg-color-light-blue-50 {
  background-color: #E1F5FE !important; }

.mui--color-light-blue-100 {
  color: #B3E5FC !important; }

.mui--bg-color-light-blue-100 {
  background-color: #B3E5FC !important; }

.mui--color-light-blue-200 {
  color: #81D4FA !important; }

.mui--bg-color-light-blue-200 {
  background-color: #81D4FA !important; }

.mui--color-light-blue-300 {
  color: #4FC3F7 !important; }

.mui--bg-color-light-blue-300 {
  background-color: #4FC3F7 !important; }

.mui--color-light-blue-400 {
  color: #29B6F6 !important; }

.mui--bg-color-light-blue-400 {
  background-color: #29B6F6 !important; }

.mui--color-light-blue-500 {
  color: #03A9F4 !important; }

.mui--bg-color-light-blue-500 {
  background-color: #03A9F4 !important; }

.mui--color-light-blue {
  color: #03A9F4 !important; }

.mui--bg-color-light-blue {
  background-color: #03A9F4 !important; }

.mui--color-light-blue-600 {
  color: #039BE5 !important; }

.mui--bg-color-light-blue-600 {
  background-color: #039BE5 !important; }

.mui--color-light-blue-700 {
  color: #0288D1 !important; }

.mui--bg-color-light-blue-700 {
  background-color: #0288D1 !important; }

.mui--color-light-blue-800 {
  color: #0277BD !important; }

.mui--bg-color-light-blue-800 {
  background-color: #0277BD !important; }

.mui--color-light-blue-900 {
  color: #01579B !important; }

.mui--bg-color-light-blue-900 {
  background-color: #01579B !important; }

.mui--color-light-blue-A100 {
  color: #80D8FF !important; }

.mui--bg-color-light-blue-A100 {
  background-color: #80D8FF !important; }

.mui--color-light-blue-A200 {
  color: #40C4FF !important; }

.mui--bg-color-light-blue-A200 {
  background-color: #40C4FF !important; }

.mui--color-light-blue-A400 {
  color: #00B0FF !important; }

.mui--bg-color-light-blue-A400 {
  background-color: #00B0FF !important; }

.mui--color-light-blue-A700 {
  color: #0091EA !important; }

.mui--bg-color-light-blue-A700 {
  background-color: #0091EA !important; }

.mui--color-cyan-50 {
  color: #E0F7FA !important; }

.mui--bg-color-cyan-50 {
  background-color: #E0F7FA !important; }

.mui--color-cyan-100 {
  color: #B2EBF2 !important; }

.mui--bg-color-cyan-100 {
  background-color: #B2EBF2 !important; }

.mui--color-cyan-200 {
  color: #80DEEA !important; }

.mui--bg-color-cyan-200 {
  background-color: #80DEEA !important; }

.mui--color-cyan-300 {
  color: #4DD0E1 !important; }

.mui--bg-color-cyan-300 {
  background-color: #4DD0E1 !important; }

.mui--color-cyan-400 {
  color: #26C6DA !important; }

.mui--bg-color-cyan-400 {
  background-color: #26C6DA !important; }

.mui--color-cyan-500 {
  color: #00BCD4 !important; }

.mui--bg-color-cyan-500 {
  background-color: #00BCD4 !important; }

.mui--color-cyan {
  color: #00BCD4 !important; }

.mui--bg-color-cyan {
  background-color: #00BCD4 !important; }

.mui--color-cyan-600 {
  color: #00ACC1 !important; }

.mui--bg-color-cyan-600 {
  background-color: #00ACC1 !important; }

.mui--color-cyan-700 {
  color: #0097A7 !important; }

.mui--bg-color-cyan-700 {
  background-color: #0097A7 !important; }

.mui--color-cyan-800 {
  color: #00838F !important; }

.mui--bg-color-cyan-800 {
  background-color: #00838F !important; }

.mui--color-cyan-900 {
  color: #006064 !important; }

.mui--bg-color-cyan-900 {
  background-color: #006064 !important; }

.mui--color-cyan-A100 {
  color: #84FFFF !important; }

.mui--bg-color-cyan-A100 {
  background-color: #84FFFF !important; }

.mui--color-cyan-A200 {
  color: #18FFFF !important; }

.mui--bg-color-cyan-A200 {
  background-color: #18FFFF !important; }

.mui--color-cyan-A400 {
  color: #00E5FF !important; }

.mui--bg-color-cyan-A400 {
  background-color: #00E5FF !important; }

.mui--color-cyan-A700 {
  color: #00B8D4 !important; }

.mui--bg-color-cyan-A700 {
  background-color: #00B8D4 !important; }

.mui--color-teal-50 {
  color: #E0F2F1 !important; }

.mui--bg-color-teal-50 {
  background-color: #E0F2F1 !important; }

.mui--color-teal-100 {
  color: #B2DFDB !important; }

.mui--bg-color-teal-100 {
  background-color: #B2DFDB !important; }

.mui--color-teal-200 {
  color: #80CBC4 !important; }

.mui--bg-color-teal-200 {
  background-color: #80CBC4 !important; }

.mui--color-teal-300 {
  color: #4DB6AC !important; }

.mui--bg-color-teal-300 {
  background-color: #4DB6AC !important; }

.mui--color-teal-400 {
  color: #26A69A !important; }

.mui--bg-color-teal-400 {
  background-color: #26A69A !important; }

.mui--color-teal-500 {
  color: #009688 !important; }

.mui--bg-color-teal-500 {
  background-color: #009688 !important; }

.mui--color-teal {
  color: #009688 !important; }

.mui--bg-color-teal {
  background-color: #009688 !important; }

.mui--color-teal-600 {
  color: #00897B !important; }

.mui--bg-color-teal-600 {
  background-color: #00897B !important; }

.mui--color-teal-700 {
  color: #00796B !important; }

.mui--bg-color-teal-700 {
  background-color: #00796B !important; }

.mui--color-teal-800 {
  color: #00695C !important; }

.mui--bg-color-teal-800 {
  background-color: #00695C !important; }

.mui--color-teal-900 {
  color: #004D40 !important; }

.mui--bg-color-teal-900 {
  background-color: #004D40 !important; }

.mui--color-teal-A100 {
  color: #A7FFEB !important; }

.mui--bg-color-teal-A100 {
  background-color: #A7FFEB !important; }

.mui--color-teal-A200 {
  color: #64FFDA !important; }

.mui--bg-color-teal-A200 {
  background-color: #64FFDA !important; }

.mui--color-teal-A400 {
  color: #1DE9B6 !important; }

.mui--bg-color-teal-A400 {
  background-color: #1DE9B6 !important; }

.mui--color-teal-A700 {
  color: #00BFA5 !important; }

.mui--bg-color-teal-A700 {
  background-color: #00BFA5 !important; }

.mui--color-green-50 {
  color: #E8F5E9 !important; }

.mui--bg-color-green-50 {
  background-color: #E8F5E9 !important; }

.mui--color-green-100 {
  color: #C8E6C9 !important; }

.mui--bg-color-green-100 {
  background-color: #C8E6C9 !important; }

.mui--color-green-200 {
  color: #A5D6A7 !important; }

.mui--bg-color-green-200 {
  background-color: #A5D6A7 !important; }

.mui--color-green-300 {
  color: #81C784 !important; }

.mui--bg-color-green-300 {
  background-color: #81C784 !important; }

.mui--color-green-400 {
  color: #66BB6A !important; }

.mui--bg-color-green-400 {
  background-color: #66BB6A !important; }

.mui--color-green-500 {
  color: #4CAF50 !important; }

.mui--bg-color-green-500 {
  background-color: #4CAF50 !important; }

.mui--color-green {
  color: #4CAF50 !important; }

.mui--bg-color-green {
  background-color: #4CAF50 !important; }

.mui--color-green-600 {
  color: #43A047 !important; }

.mui--bg-color-green-600 {
  background-color: #43A047 !important; }

.mui--color-green-700 {
  color: #388E3C !important; }

.mui--bg-color-green-700 {
  background-color: #388E3C !important; }

.mui--color-green-800 {
  color: #2E7D32 !important; }

.mui--bg-color-green-800 {
  background-color: #2E7D32 !important; }

.mui--color-green-900 {
  color: #1B5E20 !important; }

.mui--bg-color-green-900 {
  background-color: #1B5E20 !important; }

.mui--color-green-A100 {
  color: #B9F6CA !important; }

.mui--bg-color-green-A100 {
  background-color: #B9F6CA !important; }

.mui--color-green-A200 {
  color: #69F0AE !important; }

.mui--bg-color-green-A200 {
  background-color: #69F0AE !important; }

.mui--color-green-A400 {
  color: #00E676 !important; }

.mui--bg-color-green-A400 {
  background-color: #00E676 !important; }

.mui--color-green-A700 {
  color: #00C853 !important; }

.mui--bg-color-green-A700 {
  background-color: #00C853 !important; }

.mui--color-light-green-50 {
  color: #F1F8E9 !important; }

.mui--bg-color-light-green-50 {
  background-color: #F1F8E9 !important; }

.mui--color-light-green-100 {
  color: #DCEDC8 !important; }

.mui--bg-color-light-green-100 {
  background-color: #DCEDC8 !important; }

.mui--color-light-green-200 {
  color: #C5E1A5 !important; }

.mui--bg-color-light-green-200 {
  background-color: #C5E1A5 !important; }

.mui--color-light-green-300 {
  color: #AED581 !important; }

.mui--bg-color-light-green-300 {
  background-color: #AED581 !important; }

.mui--color-light-green-400 {
  color: #9CCC65 !important; }

.mui--bg-color-light-green-400 {
  background-color: #9CCC65 !important; }

.mui--color-light-green-500 {
  color: #8BC34A !important; }

.mui--bg-color-light-green-500 {
  background-color: #8BC34A !important; }

.mui--color-light-green {
  color: #8BC34A !important; }

.mui--bg-color-light-green {
  background-color: #8BC34A !important; }

.mui--color-light-green-600 {
  color: #7CB342 !important; }

.mui--bg-color-light-green-600 {
  background-color: #7CB342 !important; }

.mui--color-light-green-700 {
  color: #689F38 !important; }

.mui--bg-color-light-green-700 {
  background-color: #689F38 !important; }

.mui--color-light-green-800 {
  color: #558B2F !important; }

.mui--bg-color-light-green-800 {
  background-color: #558B2F !important; }

.mui--color-light-green-900 {
  color: #33691E !important; }

.mui--bg-color-light-green-900 {
  background-color: #33691E !important; }

.mui--color-light-green-A100 {
  color: #CCFF90 !important; }

.mui--bg-color-light-green-A100 {
  background-color: #CCFF90 !important; }

.mui--color-light-green-A200 {
  color: #B2FF59 !important; }

.mui--bg-color-light-green-A200 {
  background-color: #B2FF59 !important; }

.mui--color-light-green-A400 {
  color: #76FF03 !important; }

.mui--bg-color-light-green-A400 {
  background-color: #76FF03 !important; }

.mui--color-light-green-A700 {
  color: #64DD17 !important; }

.mui--bg-color-light-green-A700 {
  background-color: #64DD17 !important; }

.mui--color-lime-50 {
  color: #F9FBE7 !important; }

.mui--bg-color-lime-50 {
  background-color: #F9FBE7 !important; }

.mui--color-lime-100 {
  color: #F0F4C3 !important; }

.mui--bg-color-lime-100 {
  background-color: #F0F4C3 !important; }

.mui--color-lime-200 {
  color: #E6EE9C !important; }

.mui--bg-color-lime-200 {
  background-color: #E6EE9C !important; }

.mui--color-lime-300 {
  color: #DCE775 !important; }

.mui--bg-color-lime-300 {
  background-color: #DCE775 !important; }

.mui--color-lime-400 {
  color: #D4E157 !important; }

.mui--bg-color-lime-400 {
  background-color: #D4E157 !important; }

.mui--color-lime-500 {
  color: #CDDC39 !important; }

.mui--bg-color-lime-500 {
  background-color: #CDDC39 !important; }

.mui--color-lime {
  color: #CDDC39 !important; }

.mui--bg-color-lime {
  background-color: #CDDC39 !important; }

.mui--color-lime-600 {
  color: #C0CA33 !important; }

.mui--bg-color-lime-600 {
  background-color: #C0CA33 !important; }

.mui--color-lime-700 {
  color: #AFB42B !important; }

.mui--bg-color-lime-700 {
  background-color: #AFB42B !important; }

.mui--color-lime-800 {
  color: #9E9D24 !important; }

.mui--bg-color-lime-800 {
  background-color: #9E9D24 !important; }

.mui--color-lime-900 {
  color: #827717 !important; }

.mui--bg-color-lime-900 {
  background-color: #827717 !important; }

.mui--color-lime-A100 {
  color: #F4FF81 !important; }

.mui--bg-color-lime-A100 {
  background-color: #F4FF81 !important; }

.mui--color-lime-A200 {
  color: #EEFF41 !important; }

.mui--bg-color-lime-A200 {
  background-color: #EEFF41 !important; }

.mui--color-lime-A400 {
  color: #C6FF00 !important; }

.mui--bg-color-lime-A400 {
  background-color: #C6FF00 !important; }

.mui--color-lime-A700 {
  color: #AEEA00 !important; }

.mui--bg-color-lime-A700 {
  background-color: #AEEA00 !important; }

.mui--color-yellow-50 {
  color: #FFFDE7 !important; }

.mui--bg-color-yellow-50 {
  background-color: #FFFDE7 !important; }

.mui--color-yellow-100 {
  color: #FFF9C4 !important; }

.mui--bg-color-yellow-100 {
  background-color: #FFF9C4 !important; }

.mui--color-yellow-200 {
  color: #FFF59D !important; }

.mui--bg-color-yellow-200 {
  background-color: #FFF59D !important; }

.mui--color-yellow-300 {
  color: #FFF176 !important; }

.mui--bg-color-yellow-300 {
  background-color: #FFF176 !important; }

.mui--color-yellow-400 {
  color: #FFEE58 !important; }

.mui--bg-color-yellow-400 {
  background-color: #FFEE58 !important; }

.mui--color-yellow-500 {
  color: #FFEB3B !important; }

.mui--bg-color-yellow-500 {
  background-color: #FFEB3B !important; }

.mui--color-yellow {
  color: #FFEB3B !important; }

.mui--bg-color-yellow {
  background-color: #FFEB3B !important; }

.mui--color-yellow-600 {
  color: #FDD835 !important; }

.mui--bg-color-yellow-600 {
  background-color: #FDD835 !important; }

.mui--color-yellow-700 {
  color: #FBC02D !important; }

.mui--bg-color-yellow-700 {
  background-color: #FBC02D !important; }

.mui--color-yellow-800 {
  color: #F9A825 !important; }

.mui--bg-color-yellow-800 {
  background-color: #F9A825 !important; }

.mui--color-yellow-900 {
  color: #F57F17 !important; }

.mui--bg-color-yellow-900 {
  background-color: #F57F17 !important; }

.mui--color-yellow-A100 {
  color: #FFFF8D !important; }

.mui--bg-color-yellow-A100 {
  background-color: #FFFF8D !important; }

.mui--color-yellow-A200 {
  color: #FFFF00 !important; }

.mui--bg-color-yellow-A200 {
  background-color: #FFFF00 !important; }

.mui--color-yellow-A400 {
  color: #FFEA00 !important; }

.mui--bg-color-yellow-A400 {
  background-color: #FFEA00 !important; }

.mui--color-yellow-A700 {
  color: #FFD600 !important; }

.mui--bg-color-yellow-A700 {
  background-color: #FFD600 !important; }

.mui--color-amber-50 {
  color: #FFF8E1 !important; }

.mui--bg-color-amber-50 {
  background-color: #FFF8E1 !important; }

.mui--color-amber-100 {
  color: #FFECB3 !important; }

.mui--bg-color-amber-100 {
  background-color: #FFECB3 !important; }

.mui--color-amber-200 {
  color: #FFE082 !important; }

.mui--bg-color-amber-200 {
  background-color: #FFE082 !important; }

.mui--color-amber-300 {
  color: #FFD54F !important; }

.mui--bg-color-amber-300 {
  background-color: #FFD54F !important; }

.mui--color-amber-400 {
  color: #FFCA28 !important; }

.mui--bg-color-amber-400 {
  background-color: #FFCA28 !important; }

.mui--color-amber-500 {
  color: #FFC107 !important; }

.mui--bg-color-amber-500 {
  background-color: #FFC107 !important; }

.mui--color-amber {
  color: #FFC107 !important; }

.mui--bg-color-amber {
  background-color: #FFC107 !important; }

.mui--color-amber-600 {
  color: #FFB300 !important; }

.mui--bg-color-amber-600 {
  background-color: #FFB300 !important; }

.mui--color-amber-700 {
  color: #FFA000 !important; }

.mui--bg-color-amber-700 {
  background-color: #FFA000 !important; }

.mui--color-amber-800 {
  color: #FF8F00 !important; }

.mui--bg-color-amber-800 {
  background-color: #FF8F00 !important; }

.mui--color-amber-900 {
  color: #FF6F00 !important; }

.mui--bg-color-amber-900 {
  background-color: #FF6F00 !important; }

.mui--color-amber-A100 {
  color: #FFE57F !important; }

.mui--bg-color-amber-A100 {
  background-color: #FFE57F !important; }

.mui--color-amber-A200 {
  color: #FFD740 !important; }

.mui--bg-color-amber-A200 {
  background-color: #FFD740 !important; }

.mui--color-amber-A400 {
  color: #FFC400 !important; }

.mui--bg-color-amber-A400 {
  background-color: #FFC400 !important; }

.mui--color-amber-A700 {
  color: #FFAB00 !important; }

.mui--bg-color-amber-A700 {
  background-color: #FFAB00 !important; }

.mui--color-orange-50 {
  color: #FFF3E0 !important; }

.mui--bg-color-orange-50 {
  background-color: #FFF3E0 !important; }

.mui--color-orange-100 {
  color: #FFE0B2 !important; }

.mui--bg-color-orange-100 {
  background-color: #FFE0B2 !important; }

.mui--color-orange-200 {
  color: #FFCC80 !important; }

.mui--bg-color-orange-200 {
  background-color: #FFCC80 !important; }

.mui--color-orange-300 {
  color: #FFB74D !important; }

.mui--bg-color-orange-300 {
  background-color: #FFB74D !important; }

.mui--color-orange-400 {
  color: #FFA726 !important; }

.mui--bg-color-orange-400 {
  background-color: #FFA726 !important; }

.mui--color-orange-500 {
  color: #FF9800 !important; }

.mui--bg-color-orange-500 {
  background-color: #FF9800 !important; }

.mui--color-orange {
  color: #FF9800 !important; }

.mui--bg-color-orange {
  background-color: #FF9800 !important; }

.mui--color-orange-600 {
  color: #FB8C00 !important; }

.mui--bg-color-orange-600 {
  background-color: #FB8C00 !important; }

.mui--color-orange-700 {
  color: #F57C00 !important; }

.mui--bg-color-orange-700 {
  background-color: #F57C00 !important; }

.mui--color-orange-800 {
  color: #EF6C00 !important; }

.mui--bg-color-orange-800 {
  background-color: #EF6C00 !important; }

.mui--color-orange-900 {
  color: #E65100 !important; }

.mui--bg-color-orange-900 {
  background-color: #E65100 !important; }

.mui--color-orange-A100 {
  color: #FFD180 !important; }

.mui--bg-color-orange-A100 {
  background-color: #FFD180 !important; }

.mui--color-orange-A200 {
  color: #FFAB40 !important; }

.mui--bg-color-orange-A200 {
  background-color: #FFAB40 !important; }

.mui--color-orange-A400 {
  color: #FF9100 !important; }

.mui--bg-color-orange-A400 {
  background-color: #FF9100 !important; }

.mui--color-orange-A700 {
  color: #FF6D00 !important; }

.mui--bg-color-orange-A700 {
  background-color: #FF6D00 !important; }

.mui--color-deep-orange-50 {
  color: #FBE9E7 !important; }

.mui--bg-color-deep-orange-50 {
  background-color: #FBE9E7 !important; }

.mui--color-deep-orange-100 {
  color: #FFCCBC !important; }

.mui--bg-color-deep-orange-100 {
  background-color: #FFCCBC !important; }

.mui--color-deep-orange-200 {
  color: #FFAB91 !important; }

.mui--bg-color-deep-orange-200 {
  background-color: #FFAB91 !important; }

.mui--color-deep-orange-300 {
  color: #FF8A65 !important; }

.mui--bg-color-deep-orange-300 {
  background-color: #FF8A65 !important; }

.mui--color-deep-orange-400 {
  color: #FF7043 !important; }

.mui--bg-color-deep-orange-400 {
  background-color: #FF7043 !important; }

.mui--color-deep-orange-500 {
  color: #FF5722 !important; }

.mui--bg-color-deep-orange-500 {
  background-color: #FF5722 !important; }

.mui--color-deep-orange {
  color: #FF5722 !important; }

.mui--bg-color-deep-orange {
  background-color: #FF5722 !important; }

.mui--color-deep-orange-600 {
  color: #F4511E !important; }

.mui--bg-color-deep-orange-600 {
  background-color: #F4511E !important; }

.mui--color-deep-orange-700 {
  color: #E64A19 !important; }

.mui--bg-color-deep-orange-700 {
  background-color: #E64A19 !important; }

.mui--color-deep-orange-800 {
  color: #D84315 !important; }

.mui--bg-color-deep-orange-800 {
  background-color: #D84315 !important; }

.mui--color-deep-orange-900 {
  color: #BF360C !important; }

.mui--bg-color-deep-orange-900 {
  background-color: #BF360C !important; }

.mui--color-deep-orange-A100 {
  color: #FF9E80 !important; }

.mui--bg-color-deep-orange-A100 {
  background-color: #FF9E80 !important; }

.mui--color-deep-orange-A200 {
  color: #FF6E40 !important; }

.mui--bg-color-deep-orange-A200 {
  background-color: #FF6E40 !important; }

.mui--color-deep-orange-A400 {
  color: #FF3D00 !important; }

.mui--bg-color-deep-orange-A400 {
  background-color: #FF3D00 !important; }

.mui--color-deep-orange-A700 {
  color: #DD2C00 !important; }

.mui--bg-color-deep-orange-A700 {
  background-color: #DD2C00 !important; }

.mui--color-brown-50 {
  color: #EFEBE9 !important; }

.mui--bg-color-brown-50 {
  background-color: #EFEBE9 !important; }

.mui--color-brown-100 {
  color: #D7CCC8 !important; }

.mui--bg-color-brown-100 {
  background-color: #D7CCC8 !important; }

.mui--color-brown-200 {
  color: #BCAAA4 !important; }

.mui--bg-color-brown-200 {
  background-color: #BCAAA4 !important; }

.mui--color-brown-300 {
  color: #A1887F !important; }

.mui--bg-color-brown-300 {
  background-color: #A1887F !important; }

.mui--color-brown-400 {
  color: #8D6E63 !important; }

.mui--bg-color-brown-400 {
  background-color: #8D6E63 !important; }

.mui--color-brown-500 {
  color: #795548 !important; }

.mui--bg-color-brown-500 {
  background-color: #795548 !important; }

.mui--color-brown {
  color: #795548 !important; }

.mui--bg-color-brown {
  background-color: #795548 !important; }

.mui--color-brown-600 {
  color: #6D4C41 !important; }

.mui--bg-color-brown-600 {
  background-color: #6D4C41 !important; }

.mui--color-brown-700 {
  color: #5D4037 !important; }

.mui--bg-color-brown-700 {
  background-color: #5D4037 !important; }

.mui--color-brown-800 {
  color: #4E342E !important; }

.mui--bg-color-brown-800 {
  background-color: #4E342E !important; }

.mui--color-brown-900 {
  color: #3E2723 !important; }

.mui--bg-color-brown-900 {
  background-color: #3E2723 !important; }

.mui--color-grey-50 {
  color: #FAFAFA !important; }

.mui--bg-color-grey-50 {
  background-color: #FAFAFA !important; }

.mui--color-grey-100 {
  color: #F5F5F5 !important; }

.mui--bg-color-grey-100 {
  background-color: #F5F5F5 !important; }

.mui--color-grey-200 {
  color: #EEEEEE !important; }

.mui--bg-color-grey-200 {
  background-color: #EEEEEE !important; }

.mui--color-grey-300 {
  color: #E0E0E0 !important; }

.mui--bg-color-grey-300 {
  background-color: #E0E0E0 !important; }

.mui--color-grey-400 {
  color: #BDBDBD !important; }

.mui--bg-color-grey-400 {
  background-color: #BDBDBD !important; }

.mui--color-grey-500 {
  color: #9E9E9E !important; }

.mui--bg-color-grey-500 {
  background-color: #9E9E9E !important; }

.mui--color-grey {
  color: #9E9E9E !important; }

.mui--bg-color-grey {
  background-color: #9E9E9E !important; }

.mui--color-grey-600 {
  color: #757575 !important; }

.mui--bg-color-grey-600 {
  background-color: #757575 !important; }

.mui--color-grey-700 {
  color: #616161 !important; }

.mui--bg-color-grey-700 {
  background-color: #616161 !important; }

.mui--color-grey-800 {
  color: #424242 !important; }

.mui--bg-color-grey-800 {
  background-color: #424242 !important; }

.mui--color-grey-900 {
  color: #212121 !important; }

.mui--bg-color-grey-900 {
  background-color: #212121 !important; }

.mui--color-blue-grey-50 {
  color: #ECEFF1 !important; }

.mui--bg-color-blue-grey-50 {
  background-color: #ECEFF1 !important; }

.mui--color-blue-grey-100 {
  color: #CFD8DC !important; }

.mui--bg-color-blue-grey-100 {
  background-color: #CFD8DC !important; }

.mui--color-blue-grey-200 {
  color: #B0BEC5 !important; }

.mui--bg-color-blue-grey-200 {
  background-color: #B0BEC5 !important; }

.mui--color-blue-grey-300 {
  color: #90A4AE !important; }

.mui--bg-color-blue-grey-300 {
  background-color: #90A4AE !important; }

.mui--color-blue-grey-400 {
  color: #78909C !important; }

.mui--bg-color-blue-grey-400 {
  background-color: #78909C !important; }

.mui--color-blue-grey-500 {
  color: #607D8B !important; }

.mui--bg-color-blue-grey-500 {
  background-color: #607D8B !important; }

.mui--color-blue-grey {
  color: #607D8B !important; }

.mui--bg-color-blue-grey {
  background-color: #607D8B !important; }

.mui--color-blue-grey-600 {
  color: #546E7A !important; }

.mui--bg-color-blue-grey-600 {
  background-color: #546E7A !important; }

.mui--color-blue-grey-700 {
  color: #455A64 !important; }

.mui--bg-color-blue-grey-700 {
  background-color: #455A64 !important; }

.mui--color-blue-grey-800 {
  color: #37474F !important; }

.mui--bg-color-blue-grey-800 {
  background-color: #37474F !important; }

.mui--color-blue-grey-900 {
  color: #263238 !important; }

.mui--bg-color-blue-grey-900 {
  background-color: #263238 !important; }

.mui--color-black {
  color: #000 !important; }

.mui--bg-color-black {
  background-color: #000 !important; }

.mui--color-white {
  color: #FFF !important; }

.mui--bg-color-white {
  background-color: #FFF !important; }

.mui--color-black-alpha-12 {
  color: rgba(0, 0, 0, 0.12) !important; }

.mui--bg-color-black-alpha-12 {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.mui--color-black-alpha-38 {
  color: rgba(0, 0, 0, 0.38) !important; }

.mui--bg-color-black-alpha-38 {
  background-color: rgba(0, 0, 0, 0.38) !important; }

.mui--color-black-alpha-54 {
  color: rgba(0, 0, 0, 0.54) !important; }

.mui--bg-color-black-alpha-54 {
  background-color: rgba(0, 0, 0, 0.54) !important; }

.mui--color-black-alpha-87 {
  color: rgba(0, 0, 0, 0.87) !important; }

.mui--bg-color-black-alpha-87 {
  background-color: rgba(0, 0, 0, 0.87) !important; }

.mui--color-white-alpha-12 {
  color: rgba(255, 255, 255, 0.12) !important; }

.mui--bg-color-white-alpha-12 {
  background-color: rgba(255, 255, 255, 0.12) !important; }

.mui--color-white-alpha-30 {
  color: rgba(255, 255, 255, 0.3) !important; }

.mui--bg-color-white-alpha-30 {
  background-color: rgba(255, 255, 255, 0.3) !important; }

.mui--color-white-alpha-70 {
  color: rgba(255, 255, 255, 0.7) !important; }

.mui--bg-color-white-alpha-70 {
  background-color: rgba(255, 255, 255, 0.7) !important; }

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%; }
  .loading .text {
    font-size: 1.6em;
    line-height: 1.8em; }
  .loading .dots {
    width: 7.5em;
    height: 7.5em;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center; }
  .loading .dots > div {
    width: 2em;
    height: 2em;
    background-color: #039BE5;
    border-radius: 30%;
    animation: fade 1.5s alternate ease-in-out infinite; }
  .loading .dots > div:nth-of-type(2),
  .loading .dots > div:nth-of-type(4) {
    animation-delay: 0.25s; }
  .loading .dots > div:nth-of-type(3),
  .loading .dots > div:nth-of-type(5),
  .loading .dots > div:nth-of-type(7) {
    animation-delay: 0.5s; }
  .loading .dots > div:nth-of-type(6),
  .loading .dots > div:nth-of-type(8) {
    animation-delay: 0.75s; }
  .loading .dots > div:nth-of-type(9) {
    animation-delay: 1s; }
  .loading .dots > div:nth-of-type(5) {
    background-color: #FF8F00; }

@keyframes fade {
  to {
    opacity: 0.2; } }
